.homeFrame {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 85vh;
  /* overflow: hidden; */
  background-color: #0C0032;
  color: white;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
}

.utilityBox {
  display: flex;
  justify-content: space-around;
  width: 100%;
  /* height: 75px; */
  height: 12vh;
  /* background-color: teal; */
  position: relative;
  align-items: center;
}

.searchAndButtons {
  width: 25%;
  height: 50%;
  /* background-color: teal; */
  display: flex;
  position: relative;
  /* right: 250px; */
  justify-content: space-evenly;
}

/* BUTTON CUSTOMIZATION WILL GO HERE */

.daysOfTheWeekDisplay {
  display: flex;
  width: 70%;
  height: 50px;
  background-color: #282828;
  /* border-radius: 15px ; */
  justify-content: space-around;
  align-items: center;
  font-size: 1.5em;
  box-shadow: #190061 5px 5px 0px;
  /* background-color: transparent; */
  /* border: solid 1px white; */
  border-radius: 5px;
  color: white;
  
}

/* .dayHeader {
  font-weight: bold;
} */

/* .dividerLine {
  background-color: #190061;
  background-color: white;
  margin: 20px;
  width: 90vw;
  height: 1px;
  border-radius: 5px;
  position: relative;
  right: 75px;
} */

.accBox {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 73vh;
  /* overflow: auto; */
  justify-content: space-evenly;
  /* background-color: teal; */
  /* justify-content: space-around; */
}

.homeActivityBlock {
  display: flex;
  justify-content: space-around;
  /* background-color: aliceblue; */
}

.mistakeClass {
  width: 100%;
  height: 100%;
  /* background-color: teal; */
  display: flex;
  position: relative;
  /* right: 250px; */
  justify-content: space-evenly;
  align-items: center;
  text-decoration: none;
}

.specificButtonContainer {
  width: 25%;
}

.specificButtons {
  background-color: transparent;
  border: solid 1px white;
  border-radius: 5px;
  color: white;
  width: 95%;
  height: 90%;
}

.dayButtonContainer {
  box-sizing: border-box;
  display: flex;
  width: 70%;
  height: 50px;
  background-color: #282828;
  border-radius: 5px ;
  justify-content: space-around;
  align-items: center;
  font-size: 1.5em;
  box-shadow: #190061 5px 5px 0px;
}

.dayOfTheWeek {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
  position: relative;
  /* bottom: 20px; */
  color: white;
  /* border: solid 1px white; */
  background-color: transparent;
  border: 1px white solid;
  width: 15px;
  height: 10px;
  border-radius: 2px;
  /* position: relative; */
  /* left: 80px; */
}

.homeSearch {
  background-color: transparent;
  background: transparent;
  border: none;
  border-bottom: 1px solid white;
  outline: none;
  position: relative;
  /* right: 30px; */
  color: white;
}

.searchButton {
  background-color: transparent;
  border: solid 1px white;
  border-radius: 5px;
  color: white;
  position: relative;
}

.addButton {
  background-color: transparent;
  border: solid 1px white;
  border-radius: 5px;
  color: white;
  position: relative;
}

.specificButtons:hover {
  background-color: rgba(128, 128, 128, 0.514);
}

.searchButton:hover {
  background-color: rgba(128, 128, 128, 0.514);
}

.addButton:hover {
  background-color: rgba(128, 128, 128, 0.514);
}

.addActivityFrame {
  width: 100vw;
  height: 200px;
  /* background-color: aliceblue; */
  display: flex;
  justify-content: center;

  /* background-color: tomato; */
}

.justForCSS {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: space-around;
}

.newAccInput {
  background-color: transparent;
  background: transparent;
  border: none;
  border-bottom: 1px solid white;
  outline: none;
  position: relative;
  /* right: 30px; */
  color: white;
  width: 200px;
  height: 40px;
}

.newAccSubmit {
  background-color: transparent;
  border: solid 1px white;
  border-radius: 5px;
  color: white;
  width: 100px;
  height: 40px;
}

.newAccSubmit:hover {
  background-color: rgba(128, 128, 128, 0.514);
}

.dayOfTheWeek:hover {
  background-color: rgba(128, 128, 128, 0.514);
}

.dayOfTheWeekCompleted {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
  position: relative;
  /* bottom: 20px; */
  color: white;
  background-color: white;
  border: 1px white solid;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  /* position: relative; */
  /* left: 80px; */
}

.descriptionBox {
  font-size: .8em;
  width: 90%;
  /* background-color: aliceblue; */
}

@media (max-width: 1000px) {
  .daysOfTheWeekDisplay {
    /* width: 45%; */
    font-size: 1em;
    position: relative;
    /* left: 10px; */
  }

  .homeSearch {
    width: 100px;
    left: 10px;
    font-size: em;
  }

  .searchButton {
    width: 50px;
    font-size: .6em;
    left: 20px;
  }

  .addButton {
    width: 50px;
    font-size: .6em;
    left: 20px;
  }

  .dayButtonContainer {
    /* width: 60%; */
    font-size: 1em;
  }
  
  .specificButtons {
    font-size: .8em;
  }

  /* .justForCSS {
    background-color: cyan;
    width: 90%;
    position: relative;
    bottom: 0px;
    top: 20px;
  }

  .newAccInput {
    width: 100px;
  }

  .newAccSubmit {
    width: 60px;
    font-size: .8em; */
  /* } */
}

@media (max-width: 400px) {
  /* .utilityBox {
    background-color: cyan;
  } */

  /* .searchAndButtons {
    background-color: cyan;
  } */

  .daysOfTheWeekDisplay {
    width: 45%;
    font-size: .8em;
    position: relative;
    left: 10px;
  }

  .homeSearch {
    width: 50px;
    left: 10px;
    /* font-size: em; */
  }

  .searchButton {
    width: 50px;
    font-size: .5em;
    left: 30px;
  }

  .addButton {
    width: 50px;
    font-size: .5em;
    left: 30px;
  }

  .dayButtonContainer {
    width: 60%;
    font-size: 1em;
  }
  
  .specificButtons {
    font-size: .8em;
  }

  .justForCSS {
    /* background-color: cyan; */
    width: 90%;
    position: relative;
    bottom: 0px;
    top: 20px;
  }

  .newAccInput {
    width: 100px;
  }

  .newAccSubmit {
    width: 60px;
    font-size: .8em;
  }
}

.deleteButton {
  background-color: transparent;
  border: solid 1px white;
  border-radius: 5px;
  color: white;
  width: 20px;
  height: 20px;
}