.nav {
  width: 100vw;
  height: 15vh;
  background-color: aliceblue;
  display: flex;
  justify-content: space-around;
  font-family: Arial, Helvetica, sans-serif;
}

/* Text work and sizing for the Title text */

.titleText {
  font-size: 2em;
  font-weight: bold;
  /* font-family: Arial, Helvetica, sans-serif; */
}

.habitTitleText {
  font-size: 2em;
  font-weight: bold;
}

.titleBox {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  right: 100px;
  /* background-color: crimson; */
}

.navToggleBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40vw;
  height: 100%;
  /* background-color: crimson; */
  position: relative;
  left: 150px;
}

.navRightBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 100%;
  position: relative;
  left: 100px;
  /* background-color: cyan; */
}

.navButtons {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
}

.linkDeko {
  text-decoration: none;
  width: 80px;
  height: 20px;
  color: black;
  /* background-color: tomato; */
  /* border: solid 1px black; */
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.usernameBox {
  text-decoration: none;
  width: 80px;
  height: 20px;
  color: black;
  background-color: transparent;
  /* border: solid 1px black; */
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1000px) {
  .titleBox {
    right: 130px;
    width: 250px;
  }

  .titleText {
    /* background-color: cyan; */
    font-size: 1.5em;
  }

  .navToggleBox {
    width: 100px;
    left: 0px;
  }

  .navRightBox {
    left: 100px;
    right: 0px;
    width: 250px;
  }

  .navButtons {
    justify-content: space-between;
  }

  .linkDeko {
    font-size: 1em;
  }


}

@media (max-width: 400px) {
  .titleBox {
    right: 35px;
    width: 150px;
  }

  .titleText {
    /* background-color: cyan; */
    font-size: 1.5em;
  }

  .habitTitleText {
    font-size: 1.2em;
    /* background-color: cyan; */
    position: relative;
    left: 30px;
  }

  .navToggleBox {
    width: 140px;
    left: 0px;
  }

  .navRightBox {
    left: 0px;
    right: 100px;
    width: 200px;
    /* background-color: cyan; */
  }

  .navButtons {
    justify-content: space-around;
    /* width: 160px; */
    /* background-color: cyan; */
  }

  .linkDeko {
    font-size: .8em;
    width: 40px;
    /* background-color: cyan; */
  }

  .usernameBox {
    text-decoration: none;
    width: 40px;
    height: 20px;
    font-size: .8em;
    color: black;
    background-color: transparent;
    /* border: solid 1px black; */
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
