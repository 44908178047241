.landingFrame {
  display: flex;
  justify-content: flex-end;
  /* align-items:flex-end; */
  position: absolute;
  width: 100vw;
  height: 85vh;
  background-color: #0C0032;
  font-family: Arial, Helvetica, sans-serif;
  overflow: hidden;
}

.landingBox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  top: 300px;
  right: 100px;
  background-color: #282828;
  width: 450px;
  height: 200px;
  border-radius: 15px;
  box-shadow: #190061 -50px -20px 0px;
}

.boxText {
  position: relative;
  top: 15px;
  font-size: 1.8em;
  color: white;
}

.linkDeco {
  text-decoration: none;
  /* position: relative; */
  /* right: 100px */
  width: 100%;
  height: 100px;
  /* background-color: tomato; */
  /* z-index: 10; */
  display: flex;
  align-items: center;
}

.linkText {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
  position: relative;
  bottom: 10px;
  /* color: white; */
  /* border: solid 1px white; */
  /* background-color: #240090; */
  width: 200px;
  height: 40px;
  /* border-radius: 10px; */
  position: relative;
  left: 80px;
  right: 0px;
  background-color: transparent;
  border: solid 1px white;
  border-radius: 5px;
  color: white;
}

.designBox {
  width: 500px;
  height: 200px;
  background-color: #190061;
  border-radius: 10px;
  position: relative;
  top: -100px;
  right: 100px;
}

.designBox2 {
  width: 300px;
  height: 200px;
  background-color: #190061;
  border-radius: 10px;
  position: relative;
  top: 0px;
  right: -900px;
}

.designBox3 {
  width: 300px;
  height: 200px;
  background-color: #190061;
  border-radius: 10px;
  position: relative;
  top: 400px;
  right: 800px;
}

.linkText:hover {
  background-color: rgba(128, 128, 128, 0.514);
}

@media (max-width: 1000px) {
  .landingBox {
    width: 500px;
    height: 100px;
    top: 400px;
    right: 30px;
    box-sizing: border-box;
  }

  .boxText {
    font-size: 1em;
    position: relative;
    top: 10px;
    left: 3px;
  }

  .linkDeco {
    /* width: 100px; */
    height: 50px;
    /* background-color: cyan; */
    justify-content: center;
  }

  .linkText {
    width: 80px;
    height: 30px;
    left: 0px;
    bottom: 0px;
    /* right: 50px; */
    font-size: .8em;
  }
}

@media (max-width: 400px) {
  .landingBox {
    width: 1000px;
    height: 100px;
    top: 400px;
    right: 30px;
    box-sizing: border-box;
  }

  .boxText {
    font-size: 1em;
    position: relative;
    top: 10px;
    left: 3px;
  }

  .linkDeco {
    /* width: 100px; */
    height: 50px;
    /* background-color: cyan; */
    justify-content: center;
  }

  .linkText {
    width: 80px;
    height: 30px;
    left: 0px;
    bottom: 0px;
    /* right: 50px; */
    font-size: .8em;
  }

}
