.authContainer {
  width: 100vw;
  display: flex;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
  /* color: white; */
}

.authBG {
  width: 100vw;
  height: 85vh;
  position: absolute;
  background-color: #0C0032;
  display: flex;
  justify-content: center;
  /* border-radius: 15px; */
}

.authLoginFrame {
  background-color: #282828;
  width: 400px;
  height: 350px;
  position: relative;
  top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: #190061 50px 20px 0px;
}

.auth-error-msg {
  color: white;
}

.loginFrameTitle {
  display: flex;
  color: white;
  font-size: 2em;
  justify-content: flex-start;
  width: 80%;
  position: relative;
  bottom: 50px;
  left: 10px;
  font-family: Arial, Helvetica, sans-serif;
}

.authInputFields {
  /* background-color: black; */
  display: flex;
  width: 50%;
  justify-content: space-around;
  align-items: space-around;
  flex-direction: column;
  height: 100px;
  position: relative;
  bottom: 35px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  height: 25px;
  justify-content: space-between;
  position: relative;
  top: 25px;
}

.inputFieldSingle {
  background-color: transparent;
  background: transparent;
  border: none;
  outline: none;
  position: relative;
  right: 30px;
  color: white;
}

.underline {
  width: 270px;
  height: 1px;
  background-color: white;
  position: relative;
  right: 35px;

}

.authButtons {
  display: flex;
  width: 340px;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
  top: 40px;
}

.loginButtons {
  border: none;
  /* border-radius: 5px; */
  width: 120px;
  height: 30px;
  /* color: rgb(255, 255, 255); */
  /* background-color: #240090; */
  /* position: relative; */
  /* bottom: 20px; */
  background-color: transparent;
  border: solid 1px white;
  border-radius: 5px;
  color: white;
}

.loginButtons:hover {
  background-color: rgba(128, 128, 128, 0.514);
}

@media (max-width: 1000px) {

  .authLoginFrame {
    width: 400px;
    height: 350px;
    box-shadow: #190061 10px 10px 0px;
  }

  .loginFrameTitle {
    font-size: 1.7em;
    /* background-color: cyan; */
    display: flex;
    color: white;
    /* font-size: 2em; */
    justify-content: flex-start;
    width: 200px;
    position: relative;
    bottom: 60px;
    left: -40px;
    right: 100px;
    font-family: Arial, Helvetica, sans-serif;
  }

  .authInputFields {
    /* background-color: cyan; */
    width: 80%;
    left: 65px;
    bottom: 60px;
  }

  .inputFieldSingle {
    width: 200px;
    right: 10px;
    /* background-color: cyan; */
  }

  .underline {
    width: 225px;
    /* left: 0px; */
    right: 20px;
  }

  .authButtons {
    /* background-color: cyan; */
    width: 300px;
    top: 20px;
  }
}

@media (max-width: 400px) {
  .authLoginFrame {
    width: 300px;
    height: 300px;
    top: 150px;
    box-shadow: #190061 10px 10px 0px;
  }
  
  .loginFrameTitle {
    font-size: 1.5em;
    /* background-color: cyan; */
    display: flex;
    color: white;
    /* font-size: 2em; */
    justify-content: flex-start;
    width: 200px;
    position: relative;
    bottom: 50px;
    left: -10px;
    right: 100px;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .authInputFields {
    /* background-color: cyan; */
    width: 80%;
    left: 25px;
    bottom: 55px;
  }
  
  .inputFieldSingle {
    width: 200px;
    right: 10px;
    /* background-color: cyan; */
  }
  
  .underline {
    width: 225px;
    /* left: 0px; */
    right: 20px;
  }
  
  .authButtons {
    /* background-color: cyan; */
    width: 300px;
    top: 20px;
  }

}
