.react-calendar {
  width: 40%;
  margin-left: 20px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.highlight {
  background-color: grey;
}

.activityViewFrame {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 85vh;
  /* overflow: hidden; */
  background-color: #0C0032;
  /* color: white; */
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
}

.calendarBox {
  display: flex;
  flex-direction: row;
  width: 100vw;
  justify-content: flex-start;
  position: relative;
  top: 40px;
  /* background-color: aliceblue; */
}

.calendarFrame {
  padding: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 300px;
  justify-content: space-between;
}

.placeHolder {
  position: relative;
  right: 15px;
  width: 55%;
  background-color: transparent;
  border: solid 1px white;
  border-radius: 5px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;  
}

.datesCompletedTitle {
  font-size: 1.5em;
  margin: 20px;
}

.datesCompleted {
  position: relative;
  left: 3px;
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: space-evenly;
  justify-content: space-evenly;
  box-sizing: content-box;
  height: 50%;
  /* background-color: tomato; */
}

.dateDisplay {
  width: 100px;
}

.reminderFrame {
  display: flex;
  color: white;
  width: 100vw;
  height: 200px;
  /* background-color: teal; */
}

.formFrame {
  display: flex;
  width: 100%;
  height: 100%;
  /* background-color:tomato; */
  /* justify-content: space-between; */
  position: relative;
  top: 50px;
}

.actualFormatting {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* background-color: thistle; */
  width: 1000px;
  position: relative;
  left: 100px;
}

.utilityStuff {
  height: 30px;
  width: 100px;
}

.utilityInput {
  height: 24px;
  width: 100px;
  background-color: transparent;
  background: transparent;
  border: none;
  border-bottom: 1px solid white;
  outline: none;
  position: relative;
  /* right: 30px; */
  color: white;
}

.outOfPlace {
  height: 10px;
  position: relative;
  top: 150px;
  right: 850px;
  /* background-color: tomato; */
}

.submitButton {
  height: 30px;
  width: 100px;
}